<template>
  <b-container fluid="true">
    <!-- Reports Interface controls -->
    <h5 class="my-3"><strong>CRIDs</strong></h5>
    <a-row :gutter="[4, 6]">
      <a-col :xl="6" :md="12" :xs="24" class="custom-report-input-wrapper">
        <a-select
          mode="tags"
          allowClear
          placeholder="CRIDs"
          style="width: 100%"
          :showSearch="true"
          :tokenSeparators="[' ']"
          :filterOption="true" optionFilterProp="children" v-model="filteredColumns.crid.Values">
        </a-select>
        <a-button
          @click="copyCRIDs"><i class="fa fa-copy"></i></a-button>
      </a-col>
      <a-col :xl="6" :md="12" :xs="24">
        <a-select mode="single" placeholder="Ad Type" style="width: 100%" :showSearch="false" :filterOption="true"
          optionFilterProp="children" v-model="filteredColumns.adType.Values">
          <a-select-option v-for="ad in adTypes" :key="ad.value">{{ formatAdType(ad.value) }}</a-select-option>
        </a-select>
      </a-col>
      <a-col :xl="6" :md="12" :xs="24">
        <a-select mode="single" placeholder="Environment" style="width: 100%" :showSearch="false" :filterOption="true"
          optionFilterProp="children" v-model="filteredColumns.env.Values">
          <a-select-option v-for="env in environment" :key="env.value">{{ env.type }}</a-select-option>
        </a-select>
      </a-col>
      <a-col :xl="6" :md="12" :xs="24">
        <a-button type="primary" @click="setFilters" style="width: 40%">
          Apply
        </a-button>
      </a-col>
    </a-row>
    <b-row class="mt-2">
      <!-- <b-col md="6" class="my-1">
        <b-form-group label-cols-sm="2" label="Per page:" class="mb-0">
          <b-form-select v-model="perPage" :options="pageOptions" class="form-control ml-0 w-25"></b-form-select>
          <columnSelect :storageKey="'display-sites-columns'" :options="fields"></columnSelect>
        </b-form-group>
      </b-col> -->
      <b-col md="6" class="my-1">
        <b-form-group label-cols-sm="2" label="Per page:" class="mb-0">
          <perPageSelect v-model="perPage" :storageKey="'ortb-crids-library'" :pageOptions="pageOptions" />
          <!-- <b-form-select v-model="perPage" :options="pageOptions" class="form-control ml-0 w-25"></b-form-select> -->
          <columnSelect :storageKey="'display-crids-columns'" :options="fields"></columnSelect>
        </b-form-group>
      </b-col>

      <!--<b-col md="6" class="my-1 d-flex flex-row-reverse">
        <b-form-group class="mb-0">
          <b-input-group>
            <b-form-input v-model="filter" class="form-control w-50" placeholder="Type to Search"></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>-->
    </b-row>

    <!-- Main table element -->
    <b-table @sort-changed="sortingChanged" ref="table" show-empty :sort-direction="sortDirection" :small="true"
      :bordered="true" :responsive="true" :striped="true" :hover="true" stacked="md" :items="getTableData"
      :fields="filteredFields" :current-page="currentPage" :per-page="perPage" :no-local-sorting="true"
      @filtered="onFiltered" :busy="isBusy">
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle" variant="primary"></b-spinner>
        </div>
      </template>
      <template #cell(Env)="data">
        {{ formatEnv(data.item.Env) }}
      </template>
      <template #cell(AdType)="data">
        {{ formatAdType(parseInt(data.item.AdType)) }}
      </template>
      <template #cell(Iurl)="data">
        <a :href="data.item.Iurl" target="_blank">{{ data.item.Iurl }}</a>
      </template>
      <template #cell(GeoedgeAlerts)="data">
        {{ formatGeo(data.item.GeoedgeAlerts) }}
      </template>
    </b-table>
    <b-row>
      <b-col md="6" class="my-1">
        <p>Showing {{ ((currentPage - 1) * perPage).toLocaleString() }} to {{
          (((currentPage - 1) * perPage) + perPage > totalRows ? totalRows : ((currentPage - 1) * perPage) +
            perPage).toLocaleString()
        }} of {{ parseInt(totalRows).toLocaleString() }} entries</p>
      </b-col>
      <b-col md="6" class="my-1 d-flex flex-row-reverse">
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import columnSelect from '@/components/custom/tableColumnSelect'
import { getData } from '../../../api/display/crids'
import { mapState } from 'vuex'
import cridColumns from './cridFields.js'
import moment from 'moment'
import perPageSelect from '@/components/custom/PerPageSelect'
import Vue from 'vue'
export default {
  name: 'reports',
  components: { columnSelect, perPageSelect },
  data() {
    return {
      sum: {},
      sortDirection: 'desc',
      environment: [
        { type: 'All', value: 0 },
        { type: 'Web', value: 1 },
        { type: 'App', value: 2 },
      ],
      adTypes: [
        { type: 'All', value: -1 },
        { type: 'Banner', value: 0 },
        { type: 'Video', value: 1 },
        { type: 'Audio', value: 2 },
        { type: 'Native', value: 3 },
      ],
      ranges: {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, 'day'), moment().subtract(1, 'day')],
        'This Week': [moment().startOf('isoWeek'), moment()],
        'Last Week': [moment().subtract(1, 'week').startOf('isoWeek'),
          moment().subtract(1, 'week').endOf('isoWeek')],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'),
          moment().subtract(1, 'month').endOf('month')],
      },
      filteredColumns: {
        crid: {
          Column: 'Crid',
          Values: [],
        },
        iurl: {
          Column: 'Iurl',
          Contains: '',
        },
        adType: {
          Column: 'AdType',
          Values: [],
        },
        env: {
          Column: 'Env',
          Values: [],
        },
      },
      isBusy: false,
      tableData: [],
      fields: cridColumns,
      filters: [],
      currentPage: 1,
      perPage: 50,
      pageOptions: [20, 50, 100, 200],
      order: [],
      total: 0,
    }
  },
  created() {
    // this.setDateRange(this.ranges.Today)
  },
  computed: {
    filteredFields() {
      return this.fields.filter(c => c.show)
    },
    cridsString() {
      return this.filteredColumns.crid.Values.join('\n')
    },
    totalRows() {
      return this.total
    },
    hostnamesString() {
      return this.filteredColumns.hostname.Values.join('\n')
    },
    ...mapState(['info']),
  },
  methods: {
    formatGeo(str) {
      return str.replace(/,/g, ' • ')
    },
    formatEnv(val) {
      if (val === '0') {
        return 'All'
      } else if (val === '1') {
        return 'Web'
      } else if (val === '2') {
        return 'App'
      }
    },
    formatAdType(val) {
      if (val === -1) {
        return 'All'
      } else if (val === 0) {
        return 'Banner'
      } else if (val === 1) {
        return 'Video'
      } else if (val === 2) {
        return 'Audio'
      } else if (val === 3) {
        return 'Native'
      }
    },
    copyCRIDs() {
      navigator.clipboard.writeText(this.cridsString)
      Vue.prototype.$notification.success({
        message: 'CRIDs copied to clipboard.',
      })
    },
    matchIcon(array, code) {
      const cc = array.find(element => element.cc === code)
      if (cc === undefined) { return false } else {
        const image = cc.image
        return image
      }
    },
    matchStoreIcon(store) {
      if (store === '0') {
        return 'android'
      } else if (store === '1') {
        return 'apple'
      }
    },
    matchStoreName(store) {
      if (store === '0') {
        return 'Google Play'
      } else if (store === '1') {
        return 'App Store'
      }
    },
    setFilters() {
      const array = []
      for (const col in this.filteredColumns) {
        if (col === 'adType' && this.filteredColumns.adType.Values !== -1) {
          array.push(this.filteredColumns.adType)
        } else if (this.filteredColumns[col].Values && this.filteredColumns[col].Values.length !== 0) {
          array.push(this.filteredColumns[col])
        } else if (this.filteredColumns[col].Contains && this.filteredColumns[col].Contains !== '') {
          array.push(this.filteredColumns[col])
        }
      }
      this.filters = array
      this.$refs.table.refresh()
    },
    sortingChanged(ctx) {
      this.order = []
      this.order.push({
        Column: ctx.sortBy,
        Ord: ctx.sortDesc ? 'DESC' : 'ASC',
      })
      // this.$refs.table.refresh()
    },
    getTableData(ctx, callback) {
      const columns = this.fields.filter(ele => ele.show).map(ele => ele.key)
      getData(this.currentPage, this.perPage, columns, this.filters, this.order).then(response => {
        this.tableData = response.Data
        this.sum = response.Sum
        this.total = response.Total
        callback(response.Data)
      })
      return null
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    callback(value) {
      return value
    },
  },
  watch: {
    fields: {
      handler(val) {
        this.$refs.table.refresh()
      },
      deep: true,
    },
  },
}
</script>

<style scoped>
.align-column-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-report-input-wrapper {
  display: flex;
  justify-content: center;
}

.custom-report-input-wrapper>.ant-select {
  width: 100%;
  margin-right: 2px;
}

.custom-report-input-wrapper>.ant-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
}</style>
